@import url('https://fonts.googleapis.com/css?family=Merriweather|Pacifico');

$primary: #ed0e6b;
/* MAIN COLOR */
$secondary: #007999;
/* SECONDARY COLOR */
$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

html,
body {
    height: 100%;
}

body {
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“""”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    background: $blk;
    border-bottom: 5px solid $primary;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            font-family: 'Merriweather', serif;
            font-size: 20px;
            text-align: center;
            margin-top: 42px;
            display: flex;
            align-items: center;
            color: $wht;

            @media (max-width: 990px) {
                margin-top: 30px;
                font-size: 18px;
            }

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }

            &:focus,
            &:active {
                background: transparent;
                color: $wht;
                outline: 0;
            }

            &:hover {
                background: $primary;
                color: $wht;
                border-radius: 10px;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;

    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;

        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;

    .close {
        display: none;
    }

    .modal-content {
        color: $primary;

        h2 {
            text-align: center;
        }
    }

    input {
        border: 1px solid #777;
        text-align: center;
    }

    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }

    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;

        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }

    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;

    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }

        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }

        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }

    p {
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;

    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 350px;
    padding: 0.5em;
}

@media(max-width: 990px) {
    .logo {
        max-width: 265px;
    }

    .navbar-header a {
        float: left;
    }
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }

    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 175px;
    }

    .navbar-toggle {
        margin-top: 20px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 16px;
    }

    & ol {
        li {
            font-size: 16px;
        }

        & ol li {
            font-size: 15px;
        }
    }
}

.header {
   background: linear-gradient(to bottom, rgba($primary, .5), rgba($blk, .7)),
   url('../img/img1.jpg');
    background-size: cover;
    padding: 100px 0;

    @media (max-width: 500px) {
        padding: 30px 0;
        background-position: 30% 0;
    }
}

.joinTitle {
    font-family: 'Pacifico', cursive;
    font-size: 66px;
    text-align: center;
    color: $wht;
    margin-top: -60px;

    @media (max-width: 767px) {
        font-size: 40px;
        margin-top: 0;
    }
}

.dark {
    background: $blk;
    padding: 60px 0;
    color: $wht;
    text-align: center;
    height:400px;

    @media(max-width: 1130px) {
        padding: 20px 0;
         height:320px;
    }
}

.color {
    background: darken($primary, 15%);
    padding: 60px 0;
    color: $wht;
    text-align: center;
    height: 400px;

    @media(max-width: 1130px) {
        padding: 20px 0;
        height:320px;
    }
}

.info {
    padding: 100px 0;

    @media(max-width: 767px) {
        padding: 50px 0;
    }
}

.title {
    font-family: 'Merriweather', serif;
    font-size: 28px;
}

.processTitle {
    font-family: 'Merriweather', serif;
    font-size: 58px;

    @media (max-width: 500px) {
        font-size: 2em
    }
}

.center {
    display: block;
    margin: 0 auto;
}

.text {
    font-family: 'Merriweather', serif;
    font-size: 14px;

}

.wow {
    visibility: hidden;
}

.outro {
    background: linear-gradient(to bottom, rgba($primary, .5), rgba($blk, .7)),
    url('../img/outrobg.jpg');
    // background: url('../img/outrobg.jpg');
    background-size: cover;
    padding: 350px 0;
    background-position: 70%;

    @media (max-width: 767px) {
        padding: 150px 0;
    }

    @media (max-width: 500px) {
        padding: 50px 0;
        background-position: 70%;
    }
}

.outroTitle {
    font-family: 'Pacifico', cursive;
    color: $wht;
    text-align: center;
    font-size: 50px;
    text-shadow: 2px 2px 2px $primary;

    @media(max-width: 767px) {
        font-size: 2em;
    }
}

.outTag {
    font-family: 'Merriweather', serif;
    font-size: 18px;
    color: $wht;
    text-align: center;
    text-shadow: 2px 2px 1px $primary;
}